export function accordion() {

  const accordions = document.querySelectorAll('.accordion');

  for (const accordion of accordions) {
  accordion.addEventListener('click', event => {
    const target = event.target;

    if (!target.classList.contains('accordion-toggle')) {
      return;
    }

    const content = target.nextElementSibling;

    if (content.style.maxHeight) {
      content.style.maxHeight = null;
    } else {
      content.style.maxHeight = content.scrollHeight + 'px';
    }
  });
  }

  const accordion_btn = document.querySelectorAll('.accordion-toggle');

  accordion_btn.forEach(function(accordion_btn) {
  accordion_btn.addEventListener('click', function() {
    accordion_btn.classList.toggle('active');
  });
});

}



// .accordionクラスを持つ要素を全て取得し、forループで1つずつ処理。
// クリックイベントを追加し、クリックされた要素が.accordion-toggleクラスを持っているかどうかを確認。
// クリックされた.accordion-toggle要素の次の兄弟要素（.accordion-content）を取得。
// 取得した.accordion-content要素のmaxHeightスタイルが設定されている場合、nullに設定して閉じる。
// そうでない場合は、scrollHeightプロパティに設定された高さに設定して開く。